import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Link,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DialogPrivacy from "../components/DialogPrivacy";
import DialogAboutUs from "../components/DialogAboutUs";
import DialogConfirmation from "../components/DialogConfirmation";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { registerUser } from "../services/userServices";
import { useNavigate } from "react-router-dom";
import { PASS_PATTERN } from "../constants";
import Logo from "../logo192.png";

const Register = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [openRegConf, setOpenRegConf] = useState(false);
  const [openPopup1, setOpenPopup1] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 500) {
        setIsKeyboardOpen(true);
      } else {
        setIsKeyboardOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    setError("");
    if (!PASS_PATTERN.test(data.password)) {
      setError("La contraseña debe tener mayúsculas, minúsculas y números.");
      return;
    }
    if (data.agree) {
      const registerResponse = await registerUser(
        data.name,
        data.lastname,
        data.email,
        data.password,
        data.agree
      );
      if (registerResponse) {
        if (registerResponse.message) {
          setError(registerResponse.message);
        } else {
          setOpenRegConf(true);
        }
      }
    } else {
      setError("Debes aceptar la política de uso de datos para continuar");
    }
  };

  const handleConfirmation = () => {
    setOpenRegConf(false);
    navigate("/");
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.pageBackgroundSecondary,
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={2}>
        <img src={Logo} width="70%" alt={"FFCI"} />
      </Box>
      <Container maxWidth="sm" sx={{ backgroundColor: "white", padding: 4, borderRadius: 2 }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          onSubmit={handleSubmit(onSubmit)}
          component="form"
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Registro
          </Typography>
          <Typography>
            Gracias por confiar en nosotros para darte un servicio de asesoría. Por favor introduce
            la información solicitada para crear tu cuenta y comenzar con tu proceso.
          </Typography>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{ required: "Nombre es requerido" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Nombre"
                variant="outlined"
                margin="normal"
                fullWidth
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ""}
                inputProps={{ maxLength: 45 }}
              />
            )}
          />
          <Controller
            name="lastname"
            control={control}
            defaultValue=""
            rules={{ required: "Apellido es requerido" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Apellido"
                variant="outlined"
                margin="normal"
                fullWidth
                error={!!errors.lastname}
                helperText={errors.lastname ? errors.lastname.message : ""}
                inputProps={{ maxLength: 45 }}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: { value: true, message: "Correo electrónico es requerido" },
              pattern: { value: /^\S+@\S+$/i, message: "Correo electrónico no válido" }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Correo Electrónico"
                variant="outlined"
                margin="normal"
                fullWidth
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
                inputProps={{ maxLength: 50 }}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: { value: true, message: "Contraseña es requerida" }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Contraseña"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                margin="normal"
                fullWidth
                inputProps={{ maxLength: 45 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Ver contraseña"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
              />
            )}
          />
          <Controller
            name="agree"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} />}
                label="He leído y estoy de acuerdo con la política de uso de datos de la organización"
              />
            )}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ marginTop: 2 }}>
            Registrar
          </Button>
          <Typography variant="body1" color="error" sx={{ marginTop: 2 }}>
            {error}
          </Typography>
          <Link variant="body2" sx={{ marginTop: 2 }} onClick={() => navigate("/")}>
            Ya tienes una cuenta, inicia sesión
          </Link>
        </Box>
      </Container>
      <Box
        position="fixed"
        bottom={isKeyboardOpen ? -100 : 16}
        left={16}
        display="flex"
        flexDirection="column"
      >
        <Link
          href="#"
          variant="body2"
          onClick={() => setOpenPopup1(true)}
          sx={{ marginBottom: 1 }}
          className="App-link"
        >
          Acuerdo de Privacidad
        </Link>
        <Link href="#" variant="body2" onClick={() => setOpenPopup2(true)} className="App-link">
          Acerca de Nosotros
        </Link>
      </Box>
      <DialogPrivacy openPopup={openPopup1} onCloseFn={() => setOpenPopup1(false)} />
      <DialogAboutUs openPopup={openPopup2} onCloseFn={() => setOpenPopup2(false)} />
      <DialogConfirmation
        open={openRegConf}
        onCloseFn={handleConfirmation}
        dialogText={
          "Usuario registrado exitosamente. Puedes iniciar sesión ahora con las credenciales que creaste."
        }
      />
    </Box>
  );
};

export default Register;
