import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer,
  TablePagination,
  Grid,
  Tooltip,
  IconButton
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LaunchIcon from "@mui/icons-material/Launch";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { updateConsultantId } from "../redux/consultantSlice";
import { performDeepSearch } from "../services/consultantServices";

const OperatorTable = ({ consultants }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    setData(consultants);
    setFilteredData(consultants);
  }, [consultants]);

  useEffect(() => {
    setFilteredData(
      data.filter((item) => {
        const searchFields = [item.User.name, item.User.lastname, item.dpi, item.nit];
        return searchFields.some((field) =>
          field.toLowerCase().includes(searchQuery.toLowerCase())
        );
      })
    );
  }, [searchQuery, data]);

  const goToConsultant = (consultantId) => {
    function innerNav() {
      navigate("/asesoria");
    }
    dispatch(updateConsultantId(consultantId));
    setTimeout(innerNav, 500);
  };

  const deepSearch = async () => {
    if (searchQuery === "") {
      setData(consultants);
    } else {
      const results = await performDeepSearch(searchQuery);
      setData(results);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ padding: 2, borderRadius: "4px", boxShadow: 1, backgroundColor: "white" }}>
      <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 2 }}>
        <Grid item xs>
          <Button variant="contained" color="primary" startIcon={<AddIcon />}>
            Agregar Usuario
          </Button>
        </Grid>
        <Grid item>
          <TextField
            label="Busca por nombre, DPI o NIT"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={deepSearch}>
            <Tooltip title="Búsqueda profunda: Realiza una búsqueda detallada en la base de datos cuando no encuentres un registro en los datos ya cargados.">
              <SearchIcon />
            </Tooltip>
          </Button>
        </Grid>
      </Grid>
      <Paper>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Apellido</TableCell>
                <TableCell>DPI</TableCell>
                <TableCell>NIT</TableCell>
                <TableCell>Celular</TableCell>
                <TableCell>Salario</TableCell>
                <TableCell>Egresos</TableCell>
                <TableCell>Renta Neta</TableCell>
                <TableCell sx={{ width: "64px" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.User.name}</TableCell>
                    <TableCell>{item.User.lastname}</TableCell>
                    <TableCell>{item.dpi}</TableCell>
                    <TableCell>{item.nit}</TableCell>
                    <TableCell>{item.cellphone}</TableCell>
                    <TableCell>Q {item.workSalary}</TableCell>
                    <TableCell>Q {item.outcome}</TableCell>
                    <TableCell>Q {item.netIncome}</TableCell>
                    <TableCell>
                      <Tooltip title="Continuar asesoría">
                        <IconButton onClick={() => goToConsultant(item.id)}>
                          <LaunchIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default OperatorTable;

OperatorTable.propTypes = {
  consultants: PropTypes.array.isRequired
};
