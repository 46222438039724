import React from "react";
import {
  Typography,
  Container,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExamplePdf from "../docs/Reconocimiento.docx";

const HelpContent = () => {
  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        padding: "24px",
        borderRadius: "4px",
        boxShadow: 1
      }}
    >
      <Container>
        <Typography variant="h4" gutterBottom>
          Preguntas Frecuentes
        </Typography>
        <Typography variant="body1" paragraph>
          Si no encuentras la respuesta a tu pregunta, puedes contactarnos en horario hábil en
          Whatsapp +502 12345678. En caso que tengas algún problema con la aplicación, puedes
          contactar a nuestro soporte técnico enviando un correo a{" "}
          <a href="mailto:administrador@ffci-guatemala.com">administrador@ffci-guatemala.com</a>
        </Typography>
        <Typography variant="h5" marginTop="1.25rem" gutterBottom>
          Uso de la aplicación
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">¿Qué uso se le dan a mis datos?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              De acuerdo con nuestra política de privacidad, los datos personales y financieros que
              nos proporciona son utilizados estrictamente en la realización de funciones propias de
              nuestra organización y por ningún motivo serán transferidos a terceros. Los datos
              recabados nos son de utilidad para determinar tu perfil, y evaluar tu capacidad de
              pago a los créditos que han sido registrados. Comprometidos con la seguridad de tus
              datos, dicha información es guardada en servidores seguros y encriptados, y contamos
              con medidas de seguridad en esta aplicación para proteger tus datos. Al finalizar tu
              proceso de asesoría, se generará un finiquito y tus datos serán borrados de nuestra
              base de datos. Si deseas eliminar tu cuenta y tus datos antes de finalizar tu proceso
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              puedes hacerlo desde el menú "Mi cuenta".
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Typography variant="h5" marginTop="1.25rem" gutterBottom>
          Documentos
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">¿Qué es el documento de reconocimiento de deuda?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography gutterBottom>
              Este es el documento en el cual acordamos abc y que debes cargar en la sección número
              4 del formulario de tu proceso.
            </Typography>
            <Typography gutterBottom>
              <a
                href={ExamplePdf}
                download="Documento de Reconocimiento"
                target="_blank"
                rel="noreferrer"
              >
                Puedes descargarlo aquí
              </a>{" "}
              y rellenar los campos solicitados para poder continuar con el proceso.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">¿Qué es y cómo puedo obtener mi record crediticio?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography gutterBottom>
              Es un reporte escrito que contiene el registro de todos los créditos que ha solicitado
              una persona y puede solicitarse en la página de la{" "}
              <a href={"https://www.sib.gob.gt"}>Superintendencia de Bancos</a>. Puedes gestionar tu
              usuario para consulta del Historial Crediticio en el Portal de Atención al Usuario de
              la Superintendencia de Bancos, a través de la banca en línea de tu banco o
              presentandote en las oficinas de la Superintendencia de Bancos en caso de que tu banca
              en línea no cuente con dicha opción.
            </Typography>
            <Typography>
              Dentro de tu panel, selecciona la opción “Historial Cred.” y luego “Ver Historial
              Crediticio” y pulsa otra vez en la nueva opción llamada igual “Ver Historial
              Crediticio”. Antes de mostrarte el historial, el sistema te presentará dos documentos
              sobre la Ley de Acceso a la Información Pública que debes leer y que debes aceptar.
              Una vez hecho se cargará tu historial crediticio, el cual podrás descargar en formato
              PDF.
            </Typography>
            <Typography>
              Deberás cargar dicho documento en la sección número 4 de tu proceso.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">¿Qué es y cómo puedo obtener mi estado de cuenta?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Por estado de cuenta nos referimos a un documento emitido por la institución
              financiera que maneja tus cuentas de banco. Este documento recoge todos los
              movimientos que se haya realizado en una cuenta bancaria en un período de tiempo,
              generalmente 1 mes. Estos documentos generalmente son enviados a tu correo
              electrónico, pero puedes solicitarlo en servicio al cliente de tu banco si no cuentas
              con ellos por alguna razón.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">¿Por qué debo cargar una carta poder?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Cargar una carta poder es opcional en nuestro proceso de asesoría. Deberás cargarla
              únicamente si deseas delegar el poder de representación a nuestra organización para
              que realice trámites en tu nombre,{" "}
              <a href={ExamplePdf} download="Example-PDF-document" target="_blank" rel="noreferrer">
                puedes descargar un ejemplo con espacios para rellenar aquí
              </a>
              . Si no deseas cargarla, no es necesario que lo hagas.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Container>
    </Box>
  );
};

export default HelpContent;
