import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuIcon from "@mui/icons-material/Menu";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

import Logo from "../logo192.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/authProvider";
import { updateUser } from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { ROLE } from "../constants";
const SideMenu = () => {
  const dispatch = useDispatch();
  const { setToken } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const userData = useSelector((state) => state.user.user);
  const [isUser, setIsUser] = useState(false);
  const [isOperator, setIsOperator] = useState(false);
  const [isConsultant, setIsConsultant] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (userData.Roles) {
      setIsUser(userData.Roles.some((role) => role.name === ROLE.USER));
      setIsOperator(userData.Roles.some((role) => role.name === ROLE.OPERATOR));
      setIsConsultant(userData.Roles.some((role) => role.name === ROLE.CONSULTANT));
      setIsAdmin(userData.Roles.some((role) => role.name === ROLE.ADMIN));
    }
  }, [userData]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const logoutApp = () => {
    dispatch(updateUser({}));
    setToken();
    navigate("/");
  };

  return (
    <>
      {isSmallScreen && (
        <AppBar position="fixed">
          <Toolbar>
            <Box sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}>
              <img src={Logo} alt="Logo" height="60px" />
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ ml: 2 }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <Drawer
        variant={isSmallScreen ? "temporary" : "permanent"}
        open={isSmallScreen ? open : true}
        onClose={handleDrawerToggle}
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 240,
            boxSizing: "border-box",
            backgroundColor: theme.palette.primary.main,
            color: "white"
          }
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img src={Logo} width="70%" alt={"FFCI"} />
        </Box>
        <List>
          {isUser && (
            <Box sx={{ marginTop: 2 }}>
              <MenuItem
                sx={{ "&:hover": { backgroundColor: theme.palette.action.hover } }}
                onClick={() => navigate("/proceso")}
              >
                <ListItemIcon>
                  <FolderSharedIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText>
                  <ListItemText primary="Mi Proceso" />
                </ListItemText>
              </MenuItem>
            </Box>
          )}
          {(isConsultant || isOperator || isAdmin) && (
            <Box sx={{ marginTop: 2 }}>
              <MenuItem
                sx={{ "&:hover": { backgroundColor: theme.palette.action.hover } }}
                onClick={() => navigate("/asesorias")}
              >
                <ListItemIcon>
                  <DashboardIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText>
                  <ListItemText primary="Mis Asesorias" />
                </ListItemText>
              </MenuItem>
            </Box>
          )}
          {isAdmin && (
            <Box sx={{ marginTop: 2 }}>
              <MenuItem
                sx={{ "&:hover": { backgroundColor: theme.palette.action.hover } }}
                onClick={() => navigate("/administracion")}
              >
                <ListItemIcon>
                  <SettingsIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText>
                  <ListItemText primary="Administración" />
                </ListItemText>
              </MenuItem>
            </Box>
          )}
          <Box sx={{ marginTop: 2 }}>
            <MenuItem
              sx={{ "&:hover": { backgroundColor: theme.palette.action.hover } }}
              onClick={() => navigate("/ayuda")}
            >
              <ListItemIcon>
                <HelpCenterIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText>
                <ListItemText primary="Ayuda y Documentos" />
              </ListItemText>
            </MenuItem>
          </Box>
          <Box sx={{ marginTop: 2 }}>
            <MenuItem
              sx={{ "&:hover": { backgroundColor: theme.palette.action.hover } }}
              onClick={() => navigate("/cuenta")}
            >
              <ListItemIcon>
                <AccountCircleIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText>
                <ListItemText primary="Mi Cuenta" />
              </ListItemText>
            </MenuItem>
          </Box>
        </List>
        <Box sx={{ flexGrow: 1 }} />
        <List>
          <MenuItem
            sx={{ "&:hover": { backgroundColor: theme.palette.action.hover } }}
            onClick={logoutApp}
          >
            <ListItemIcon>
              <LogoutIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText>
              <ListItemText primary="Cerrar Sesión" onClick={logoutApp} />
            </ListItemText>
          </MenuItem>
        </List>
      </Drawer>
    </>
  );
};

export default SideMenu;
