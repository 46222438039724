import React, { useEffect, useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Box
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import SideMenu from "../components/SideMenu";
import { useTheme } from "@mui/material/styles";
import ConsultantForm from "../components/ConsultantForm";
import CreditsForm from "../components/CreditsForm";
import DocumentsForm from "../components/DocumentsForm";
import DebtReasons from "../components/DebtReasons";
import { useSelector } from "react-redux";
import { getConsultantByUserId } from "../services/consultantServices";
import { getCreditByConsultant } from "../services/creditServices";
import { getReasonsByConsultant } from "../services/reasonsServices";
import { getDocumentByConsultant } from "../services/docsServices";

const UserDashboard = () => {
  const theme = useTheme();
  const userData = useSelector((state) => state.user.user);
  const [consultant, setConsultant] = useState({});
  const [credits, setCredits] = useState([]);
  const [reasons, setReasons] = useState(null);
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      if (userData.id) {
        const response = await getConsultantByUserId(userData.id);
        if (!ignore) {
          setConsultant(response);
        }
      }
    }
    fetchData();
    return () => {
      ignore = true;
    };
  }, [userData]);

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      if (consultant && consultant.id) {
        const credits = await getCreditByConsultant(consultant.id);
        const reasons = await getReasonsByConsultant(consultant.id);
        const docs = await getDocumentByConsultant(consultant.id);
        if (!ignore) {
          setCredits(credits);
          setReasons(reasons);
          setDocs(docs);
        }
      }
    }
    fetchData();
    return () => {
      ignore = true;
    };
  }, [consultant]);

  return (
    <Box sx={{ backgroundColor: theme.palette.pageBackground, minHeight: "100vh" }}>
      <div style={{ display: "flex" }}>
        <SideMenu />
        <main style={{ flexGrow: 1, padding: "24px" }}>
          <div style={{ minHeight: "64px" }} />
          <Container>
            <Typography variant="h4" gutterBottom>
              {`¡Bienvenido, ${userData.name} ${userData.lastname}!`}
            </Typography>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">Instrucciones</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Gracias por tu interés en tomar una asesoría en FFCI. Te solicitamos que a
                  continuación puedas completar tu formulario de información básica para poder
                  comenzar con la asesoría. Además, puedes subir los archivos que consideres
                  necesarios para poder revisar tu caso.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">1. Provee tu información personal y laboral</Typography>
                {consultant.completedAt && (
                  <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                    <CheckCircleIcon color="success" />
                    <Typography variant="body2" sx={{ marginLeft: "8px" }}>
                      Completado
                    </Typography>
                  </Box>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  Esta información nos será de utilidad para evaluar tu situación específica y poder
                  ofrecer ofrecer la mejor solución posible en tu asesoría con base a tu perfil
                  personal específico.
                </Typography>
                <ConsultantForm consultant={consultant} onModelChange={setConsultant} />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                disabled={consultant && !consultant.completedAt}
              >
                <Typography variant="h5">2. ¿Por qué crees que te endeudaste?</Typography>
                {reasons && reasons.createdAt && (
                  <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                    <CheckCircleIcon color="success" />
                    <Typography variant="body2" sx={{ marginLeft: "8px" }}>
                      Completado
                    </Typography>
                  </Box>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  En función de comenzar con tu asesoría, nos gustaría saber cuáles crees que fueron
                  las razones por las cuales te endeudaste.
                </Typography>
                <DebtReasons
                  reasons={reasons}
                  consultantId={consultant.id}
                  onModelChange={setReasons}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} disabled={reasons === null}>
                <Typography variant="h5">3. Provee la información sobre tus créditos</Typography>
                {credits.length > 0 && (
                  <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                    <InfoIcon color="primary" />
                    <Typography variant="body2" sx={{ marginLeft: "8px" }}>
                      {`${credits.length} agregado(s)`}
                    </Typography>
                  </Box>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  Utilizaremos esta información para conocer tu situación financiera actual y al
                  complementarse con la información sobre tu perfil personal, poder ayudarte para
                  saldar dichos créditos.
                </Typography>
                <CreditsForm
                  creditList={credits}
                  consultantId={consultant.id}
                  onModelChange={setCredits}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} disabled={credits.length === 0}>
                <Typography variant="h5">4. Sube tus documentos</Typography>
                {docs.length > 0 && (
                  <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                    <InfoIcon color="primary" />
                    <Typography variant="body2" sx={{ marginLeft: "8px" }}>
                      {`${docs.length} agregado(s)`}
                    </Typography>
                  </Box>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  Por favor carga los documentos que consideres necesarios para tu asesoria. Puedes
                  subir archivos en formato JPG, PNG o PDF. Los documentos mínimos que consideramos
                  ser requeridos para tu asesoria son: DPI o documento de identificación, record
                  crediticio y estados de cuenta.
                </Typography>
                <DocumentsForm docs={docs} consultantId={consultant.id} onModelChange={setDocs} />
              </AccordionDetails>
            </Accordion>
          </Container>
        </main>
      </div>
    </Box>
  );
};

export default UserDashboard;
