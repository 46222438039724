import axios from "axios";
import { API_URL } from "../constants";

export const userLogin = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/user/login`, { email, password });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};

export const registerUser = async (name, lastname, email, password, policyAgreement) => {
  try {
    const response = await axios.post(`${API_URL}/user/register`, {
      name,
      lastname,
      email,
      password,
      policyAgreement
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};

export const updateUser = async (userId, email, password, newPassword) => {
  try {
    const response = await axios.put(`${API_URL}/user/${userId}`, {
      email,
      password,
      newPassword
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await axios.delete(`${API_URL}/user/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};
