import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  TablePagination
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import PropTypes from "prop-types";

const ManagePermissionsDialog = ({ open, onClose, user, onSave }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Manage Permissions</DialogTitle>
    <DialogContent>
      <Typography>{user?.firstname}</Typography>
      <List>
        {["Usuario Asesorado", "Operador", "Asesor", "Administrador"].map((permission) => (
          <ListItem key={permission}>
            <ListItemText primary={permission} />
            <ListItemSecondaryAction>
              <Checkbox edge="end" />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancelar
      </Button>
      <Button onClick={onSave} color="primary" variant="contained">
        Guardar
      </Button>
    </DialogActions>
  </Dialog>
);

ManagePermissionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstname: PropTypes.string
  }),
  onSave: PropTypes.func.isRequired
};

const MainComponent = () => {
  const [permissionsDialogOpen, setPermissionsDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const handleManagePermissions = (user) => {
    setSelectedUser(user);
    setPermissionsDialogOpen(true);
  };

  const handleSavePermissions = () => {
    // Save permissions logic here
    setPermissionsDialogOpen(false);
  };

  return (
    <Box sx={{ backgroundColor: "white", borderRadius: "4px", boxShadow: 1, padding: "24px" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Administración de usuarios
          </Typography>
          <TextField
            label="Introduce correo electrónico o nombres para buscar un usuario"
            variant="outlined"
            fullWidth
          />
          <Button variant="contained" color="primary" sx={{ marginTop: "16px" }}>
            Buscar
          </Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Apellido</TableCell>
                  <TableCell>Correo Electrónico</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Replace with dynamic data */}
                <TableRow>
                  <TableCell>John</TableCell>
                  <TableCell>Doe</TableCell>
                  <TableCell>john.doe@example.com</TableCell>
                  <TableCell>
                    <Button color="primary">Reiniciar Contraseña</Button>
                    <Button
                      color="primary"
                      onClick={() => handleManagePermissions({ firstname: "John" })}
                    >
                      Cambiar Permisos
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={100} // Replace with dynamic count
              rowsPerPage={5}
              page={0}
              onPageChange={() => {}}
              onRowsPerPageChange={() => {}}
            />
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Eliminar casos viejos
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Desde"
                  value={dateFrom}
                  onChange={setDateFrom}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Hasta"
                  value={dateTo}
                  onChange={setDateTo}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" sx={{ marginTop: "16px" }}>
            Buscar
          </Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox />
                  </TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Apellido</TableCell>
                  <TableCell>Fecha de finalización</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Replace with dynamic data */}
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox />
                  </TableCell>
                  <TableCell>Jane</TableCell>
                  <TableCell>Smith</TableCell>
                  <TableCell>2023-10-01</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={100} // Replace with dynamic count
              rowsPerPage={5}
              page={0}
              onPageChange={() => {}}
              onRowsPerPageChange={() => {}}
            />
          </TableContainer>
          <Button variant="contained" color="secondary" sx={{ marginTop: "16px" }}>
            Eliminar seleccionados
          </Button>
        </Grid>
      </Grid>
      <ManagePermissionsDialog
        open={permissionsDialogOpen}
        onClose={() => setPermissionsDialogOpen(false)}
        user={selectedUser}
        onSave={handleSavePermissions}
      />
    </Box>
  );
};

export default MainComponent;
