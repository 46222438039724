import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import UserDashboard from "./pages/UserDashboard";
import OperatorDashboard from "./pages/OperatorDashboard";
import Administration from "./pages/Administration";
import Account from "./pages/Account";
import Consultant from "./pages/Consultant";
import Help from "./pages/Help";
import { PrivateElement } from "./auth/PrivateElement";
import { useSelector } from "react-redux";
import useTokenExpirationCheck from "./auth/tokenChecks";

function App() {
  const userData = useSelector((state) => state.user.user);
  useTokenExpirationCheck(userData.token);
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/registro" element={<Register />} />
      <Route
        path="/proceso"
        element={
          <PrivateElement>
            <UserDashboard />
          </PrivateElement>
        }
      />
      <Route
        path="/asesorias"
        element={
          <PrivateElement>
            <OperatorDashboard />
          </PrivateElement>
        }
      />
      <Route
        path="/asesoria"
        element={
          <PrivateElement>
            <Consultant />
          </PrivateElement>
        }
      />
      <Route
        path="/administracion"
        element={
          <PrivateElement>
            <Administration />
          </PrivateElement>
        }
      />
      <Route
        path="/ayuda"
        element={
          <PrivateElement>
            <Help />
          </PrivateElement>
        }
      />
      <Route
        path="/cuenta"
        element={
          <PrivateElement>
            <Account />
          </PrivateElement>
        }
      />
    </Routes>
  );
}

export default App;
