import React, { useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  InputAdornment
} from "@mui/material";
import PropTypes from "prop-types";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import { ROLE } from "../constants";
import { createConsultant } from "../services/consultantServices";
import Loading from "./Loading";
import { updateUser } from "../redux/userSlice";
import { produce } from "immer";

const ConsultantForm = ({ consultant, onModelChange }) => {
  const userData = useSelector((state) => state.user.user);
  const {
    watch,
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: useMemo(() => {
      if (consultant && consultant.User) {
        return { ...consultant, name: consultant.User.name, lastname: consultant.User.lastname };
      } else {
        return { ...consultant, name: userData.name, lastname: userData.lastname };
      }
    }, [consultant, userData])
  });
  const dispatch = useDispatch();
  const [showOperatorFields, setShowOperatorFields] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [showV1, setShowV1] = useState(false);
  const [showV2, setShowV2] = useState(false);
  const watchVehicles = watch("vehicles");

  useEffect(() => {
    const value = Number(watchVehicles);
    if (Number.isInteger(value)) {
      if (value > 0) {
        setShowV1(true);
      } else {
        setShowV1(false);
      }
      if (value > 1) {
        setShowV2(true);
      } else {
        setShowV2(false);
      }
    }
  }, [watchVehicles]);
  useEffect(() => {
    reset(consultant);
    if (consultant && consultant.User) {
      setValue("name", consultant.User.name);
      setValue("lastname", consultant.User.lastname);
    }
    /* eslint-disable-next-line */
  }, [consultant]);

  useEffect(() => {
    if (userData.Roles) {
      setShowOperatorFields(userData.Roles.some((role) => role.name !== ROLE.USER));
    }
  }, [userData]);

  const onSubmit = async (data) => {
    setShowLoading(true);
    const payload = produce(data, (draft) => {
      Object.keys(draft).forEach((key) => {
        if (draft[key] === "") {
          draft[key] = undefined;
        }
      });
    });
    const consultantResponse = await createConsultant({
      ...payload,
      consultantId: consultant.id,
      userId: userData.id
    });
    onModelChange(consultantResponse);
    dispatch(updateUser({ ...userData, name: payload.name, lastname: payload.lastname }));
    setShowLoading(false);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ maxWidth: "85%", margin: "auto", padding: 2 }}
    >
      <Typography variant="h6" gutterBottom>
        A. Datos Personales
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{ required: "Nombre es requerido" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Nombre"
                variant="outlined"
                fullWidth
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ""}
                inputProps={{ maxLength: 45 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="lastname"
            control={control}
            defaultValue=""
            rules={{ required: "Apellido es requerido" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Apellido"
                variant="outlined"
                fullWidth
                error={!!errors.lastname}
                helperText={errors.lastname ? errors.lastname.message : ""}
                inputProps={{ maxLength: 45 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="age"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Edad"
                variant="outlined"
                fullWidth
                error={!!errors.age}
                helperText={errors.age ? errors.age.message : ""}
                type="number"
                inputProps={{ min: 0, max: 120 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="dpi"
            control={control}
            defaultValue=""
            rules={{ required: "DPI es requerido" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="DPI"
                variant="outlined"
                fullWidth
                error={!!errors.dpi}
                helperText={errors.dpi ? errors.dpi.message : ""}
                inputProps={{ maxLength: 20 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="nit"
            control={control}
            defaultValue=""
            rules={{ required: "NIT es requerido" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="NIT"
                variant="outlined"
                fullWidth
                error={!!errors.dni}
                helperText={errors.dni ? errors.dni.message : ""}
                inputProps={{ maxLength: 20 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="phone"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Teléfono"
                variant="outlined"
                fullWidth
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : ""}
                inputProps={{ maxLength: 40 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="cellphone"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Teléfono Celular"
                variant="outlined"
                fullWidth
                error={!!errors.cellphone}
                helperText={errors.cellphone ? errors.cellphone.message : ""}
                inputProps={{ maxLength: 40 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="workPhone"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Teléfono de Trabajo"
                variant="outlined"
                fullWidth
                error={!!errors.workPhone}
                helperText={errors.workPhone ? errors.workPhone.message : ""}
                inputProps={{ maxLength: 40 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Controller
                name="livesWithFamiliar"
                control={control}
                defaultValue={false}
                render={({ field: { value, ref, ...field } }) => (
                  <Checkbox
                    {...field}
                    inputRef={ref}
                    checked={!!value}
                    color="primary"
                    size="medium"
                  />
                )}
              />
            }
            label="Vive con algun familiar"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="address"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Dirección"
                variant="outlined"
                fullWidth
                error={!!errors.address}
                helperText={errors.address ? errors.address.message : ""}
                inputProps={{ maxLength: 100 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Controller
                name="ownHome"
                control={control}
                defaultValue={false}
                render={({ field: { value, ref, ...field } }) => (
                  <Checkbox
                    {...field}
                    inputRef={ref}
                    checked={!!value}
                    color="primary"
                    size="medium"
                  />
                )}
              />
            }
            label="Casa propia"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Controller
                name="rentHome"
                control={control}
                defaultValue={false}
                render={({ field: { value, ref, ...field } }) => (
                  <Checkbox
                    {...field}
                    inputRef={ref}
                    checked={!!value}
                    color="primary"
                    size="medium"
                  />
                )}
              />
            }
            label="Renta"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="rentAmount"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Costo de la Renta"
                variant="outlined"
                fullWidth
                error={!!errors.rentAmount}
                helperText={errors.rentAmount ? errors.rentAmount.message : ""}
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">Q</InputAdornment>
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="dependants"
            control={control}
            defaultValue=""
            rules={{ required: "Dependientes es requerido" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Número de dependientes"
                variant="outlined"
                fullWidth
                error={!!errors.dependants}
                helperText={errors.dependants ? errors.dependants.message : ""}
                type="number"
                inputProps={{ min: 0, max: 99 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="vehicles"
            control={control}
            defaultValue=""
            rules={{ required: "Vehiculos es requerido" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Número de vehiculos que posee"
                variant="outlined"
                fullWidth
                error={!!errors.vehicles}
                helperText={errors.vehicles ? errors.vehicles.message : ""}
                type="number"
                inputProps={{ min: 0, max: 99 }}
              />
            )}
          />
        </Grid>
        {showV1 && (
          <Grid item xs={12} sm={4}>
            <Controller
              name="vehicle1Brand"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Marca de vehiculo principal"
                  variant="outlined"
                  fullWidth
                  error={!!errors.vehicle1Brand}
                  helperText={errors.vehicle1Brand ? errors.vehicle1Brand.message : ""}
                  inputProps={{ maxLength: 45 }}
                />
              )}
            />
          </Grid>
        )}
        {showV1 && (
          <Grid item xs={12} sm={4}>
            <Controller
              name="vehicle1Model"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Modelo de vehiculo principal"
                  variant="outlined"
                  fullWidth
                  error={!!errors.vehicle1Model}
                  helperText={errors.vehicle1Model ? errors.vehicle1Model.message : ""}
                  inputProps={{ maxLength: 45 }}
                />
              )}
            />
          </Grid>
        )}
        {showV1 && (
          <Grid item xs={12} sm={4}>
            <Controller
              name="vehicle1Year"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Año de vehiculo principal"
                  variant="outlined"
                  fullWidth
                  error={!!errors.vehicle1Year}
                  helperText={errors.vehicle1Year ? errors.vehicle1Year.message : ""}
                  type="number"
                  inputProps={{ min: 1900, max: 2100 }}
                />
              )}
            />
          </Grid>
        )}
        {showV2 && (
          <Grid item xs={12} sm={4}>
            <Controller
              name="vehicle2Brand"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Marca de vehiculo secundario"
                  variant="outlined"
                  fullWidth
                  error={!!errors.vehicle2Brand}
                  helperText={errors.vehicle2Brand ? errors.vehicle2Brand.message : ""}
                  inputProps={{ maxLength: 45 }}
                />
              )}
            />
          </Grid>
        )}
        {showV2 && (
          <Grid item xs={12} sm={4}>
            <Controller
              name="vehicle2Model"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Modelo de vehiculo secundario"
                  variant="outlined"
                  fullWidth
                  error={!!errors.vehicle2Model}
                  helperText={errors.vehicle2Model ? errors.vehicle2Model.message : ""}
                  inputProps={{ maxLength: 45 }}
                />
              )}
            />
          </Grid>
        )}
        {showV2 && (
          <Grid item xs={12} sm={4}>
            <Controller
              name="vehicle2Year"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Año de vehiculo secundario"
                  variant="outlined"
                  fullWidth
                  error={!!errors.vehicle2Year}
                  helperText={errors.vehicle2Year ? errors.vehicle2Year.message : ""}
                  type="number"
                  inputProps={{ min: 1900, max: 2100 }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Controller
            name="maritalStatus"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl fullWidth variant="outlined">
                <InputLabel>Estado Civil</InputLabel>
                <Select {...field} label="Estado Civil" error={!!errors.maritalStatus}>
                  <MenuItem value="SOLTERO">Soltero</MenuItem>
                  <MenuItem value="CASADO">Casado</MenuItem>
                  <MenuItem value="DIVORCIO">Divorciado</MenuItem>
                  <MenuItem value="UNION">Unión de hecho</MenuItem>
                </Select>
                {errors.maritalStatus && (
                  <Typography color="error">{errors.maritalStatus.message}</Typography>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="children"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Número de hijos"
                variant="outlined"
                fullWidth
                error={!!errors.children}
                helperText={errors.children ? errors.children.message : ""}
                type="number"
                inputProps={{ min: 0, max: 99 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="spouseName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Nombre de cónyuge"
                variant="outlined"
                fullWidth
                error={!!errors.spouseName}
                helperText={errors.spouseName ? errors.spouseName.message : ""}
                inputProps={{ maxLength: 85 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="spouseEmail"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Email de cónyuge"
                variant="outlined"
                fullWidth
                error={!!errors.spouseEmail}
                helperText={errors.spouseEmail ? errors.spouseEmail.message : ""}
                inputProps={{ maxLength: 50 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="spouseDpi"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="DPI de cónyuge"
                variant="outlined"
                fullWidth
                error={!!errors.spouseDpi}
                helperText={errors.spouseDpi ? errors.spouseDpi.message : ""}
                inputProps={{ maxLength: 30 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="spouseAge"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Edad de cónyuge"
                variant="outlined"
                fullWidth
                error={!!errors.spouseDni}
                helperText={errors.spouseDni ? errors.spouseDni.message : ""}
                type="number"
                inputProps={{ min: 0, max: 120 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="spousePhone"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Teléfono de cónyuge"
                variant="outlined"
                fullWidth
                error={!!errors.spousePhone}
                helperText={errors.spousePhone ? errors.spousePhone.message : ""}
                inputProps={{ maxLength: 40 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="education"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl fullWidth variant="outlined">
                <InputLabel>Nivel educativo</InputLabel>
                <Select {...field} label="Nivel Educativo" error={!!errors.education}>
                  <MenuItem value="PRIMARIA">Primaria</MenuItem>
                  <MenuItem value="BASICOS">Básicos</MenuItem>
                  <MenuItem value="DIVERSIFICADO">Educación Media</MenuItem>
                  <MenuItem value="UNIVERSITARIO">Universitario</MenuItem>
                </Select>
                {errors.education && (
                  <Typography color="error">{errors.education.message}</Typography>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="otherEducation"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Diplomados u otra educación"
                variant="outlined"
                fullWidth
                error={!!errors.otherEducation}
                helperText={errors.otherEducation ? errors.otherEducation.message : ""}
                inputProps={{ maxLength: 45 }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom sx={{ marginTop: "1rem" }}>
        B. Datos Laborales
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="workCompanyName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Nombre Empresa"
                variant="outlined"
                fullWidth
                error={!!errors.workCompanyName}
                helperText={errors.workCompanyName ? errors.workCompanyName.message : ""}
                inputProps={{ maxLength: 45 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="workCompanyPhone"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Teléfono"
                variant="outlined"
                fullWidth
                error={!!errors.workCompanyPhone}
                helperText={errors.workCompanyPhone ? errors.workCompanyPhone.message : ""}
                inputProps={{ maxLength: 40 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            name="workCompanyAddress"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Dirección"
                variant="outlined"
                fullWidth
                error={!!errors.workCompanyAddress}
                helperText={errors.workCompanyAddress ? errors.workCompanyAddress.message : ""}
                inputProps={{ maxLength: 100 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="workJobName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Puesto"
                variant="outlined"
                fullWidth
                error={!!errors.workJobName}
                helperText={errors.workJobName ? errors.workJobName.message : ""}
                inputProps={{ maxLength: 45 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="workSalary"
            control={control}
            defaultValue=""
            rules={{ required: "Salario es requerido" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Salario"
                variant="outlined"
                fullWidth
                error={!!errors.workSalary}
                helperText={errors.workSalary ? errors.workSalary.message : ""}
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">Q</InputAdornment>
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="workTime"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Tiempo de laborar en la empresa"
                variant="outlined"
                fullWidth
                error={!!errors.workTime}
                helperText={errors.workTime ? errors.workSalary.message : ""}
                inputProps={{ maxLength: 20 }}
              />
            )}
          />
        </Grid>
      </Grid>
      {showOperatorFields && (
        <>
          <Typography variant="h6" gutterBottom sx={{ marginTop: "1rem" }}>
            C. Campos de asesoría
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="outcome"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Egresos"
                    variant="outlined"
                    fullWidth
                    error={!!errors.outcome}
                    helperText={errors.outcome ? errors.outcome.message : ""}
                    type="number"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="netIncome"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Renta Neta"
                    variant="outlined"
                    fullWidth
                    error={!!errors.netIncome}
                    helperText={errors.netIncome ? errors.netIncome.message : ""}
                    type="number"
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            endIcon={<SaveIcon />}
          >
            Guardar Información
          </Button>
        </Grid>
      </Grid>
      <Loading show={showLoading} text={"Guardando"} />
    </Box>
  );
};

export default ConsultantForm;

ConsultantForm.propTypes = {
  consultant: PropTypes.object,
  onModelChange: PropTypes.func.isRequired
};
