/* eslint-disable */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { updateUser } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import { useAuth } from "./authProvider";

const useTokenExpirationCheck = (token) => {
  const dispatch = useDispatch();
  const { setToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (!token) {
        dispatch(updateUser({}));
        setToken();
        navigate("/");
        return;
      }

      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      const timeLeft = decodedToken.exp - currentTime;

      if (timeLeft <= 600) {
        // 10 minutes in seconds
        dispatch(updateUser({}));
        setToken();
        navigate("/");
      }
    };

    const intervalId = setInterval(checkTokenExpiration, 600000); // 10 minutes in milliseconds

    // Initial check
    checkTokenExpiration();

    return () => clearInterval(intervalId);
  }, [token, navigate]);
};

export default useTokenExpirationCheck;
